<template>
	<v-row no-gutters dense class="list-home mt-0">
		<v-col cols="12" class="pa-0">
			<v-row no-gutters>
				<v-col cols="6">
					<p class="my-heading">Lists</p>
				</v-col>
				<v-col cols="6" class="header-link d-sm-flex d-none justify-end align-center pb-7">
					<span v-on:click="filterList()" class="d-flex align-center" style="font-size: 18px">
						Filter
						<img :src="$store.state.icons.icons['filter-icon']" style="height: 24px" class="pl-1" alt />
					</span>
				</v-col>
			</v-row>

			<div v-if="no_data === true">
				<div class="d-flex align-center justify-center" style="height: 70vh">
					<p class="no-data">No lists available</p>
				</div>
			</div>

			<div v-if="lists">
				<div v-if="lists['new_list'] && lists['new_list'].length > 0">
					<p class="delivery-sf20 pb-5 pt-8 ma-0">
						New Lists
						<span class="delivery-mf15">• {{ lists['new_list'].length }} lists</span>
					</p>

					<transition-group name="slide-in" :style="{ '--total': lists['new_list'].length }">
						<v-row
							v-for="(data, index) in lists['new_list']"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center col-2">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }} - {{ data.contractor.fullname }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>
								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0 text-tag-grey" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn
										v-if="!data.bid.status"
										class="my-p-btn my_elevation"
										id="my_elevation"
										depressed
										@click.prevent="bid(data.list.list_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Quote</v-btn
									>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>

				<div v-if="lists['active'] && lists['active'].length > 0">
					<p class="delivery-sf20 pb-5 pt-8 ma-0">
						Active Quotes
						<span class="delivery-mf15">• {{ lists['active'].length }} quotes</span>
					</p>

					<transition-group name="slide-in" :style="{ '--total': lists['active'].length }">
						<v-row
							v-for="(data, index) in lists['active']"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }} - {{ data.contractor.fullname }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>
								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0 text-tag-grey" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn
										v-if="data.bid.status === 'AC'"
										class="my-p-btn my_elevation check-quote-btn"
										id="my_elevation"
										depressed
										@click.prevent="checkBid(data.bid.bid_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Check Quote</v-btn
									>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>

				<div v-if="lists['expired'] && lists['expired'].length > 0">
					<p class="delivery-sf20 pb-5 pt-8 ma-0">
						Expired Quotes
						<span class="delivery-mf15">• {{ lists['expired'].length }} quotes</span>
					</p>

					<transition-group name="slide-in" :style="{ '--total': lists['expired'].length }">
						<v-row
							v-for="(data, index) in lists['expired']"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }} - {{ data.contractor.fullname }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>

								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0 text-tag-grey" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn
										v-if="data.bid.status === 'R' || data.bid.status === 'E'"
										class="my-p-btn my_elevation re-quote-btn"
										id="my_elevation"
										depressed
										@click.prevent="reBid(data.bid.bid_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Re-Quote</v-btn
									>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>

				<div v-if="lists['rejected'] && lists['rejected'].length > 0">
					<p class="delivery-sf20 pb-5 pt-8 ma-0">
						Rejected Quotes
						<span class="delivery-mf15">• {{ lists['rejected'].length }} quotes</span>
					</p>

					<transition-group name="slide-in" :style="{ '--total': lists['rejected'].length }">
						<v-row
							v-for="(data, index) in lists['rejected']"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }} - {{ data.contractor.fullname }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>
								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0 text-tag-grey" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn
										v-if="(data.bid.status === 'R' || data.bid.status === 'E') && !(data.list.status === 'E' || data.list.status === 'BA')"
										class="my-p-btn my_elevation re-quote-btn"
										id="my_elevation"
										depressed
										@click.prevent="reBid(data.bid.bid_id)"
										:disabled="data.list.status === 'E' || data.list.status === 'BA'"
										>Re-Quote</v-btn
									>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>

				<div v-if="lists['loss_bid'] && lists['loss_bid'].length > 0">
					<p class="delivery-sf20 pb-5 pt-8 ma-0">
						Loss Quotes
						<span class="delivery-mf15">• {{ lists['loss_bid'].length }} quotes</span>
					</p>

					<transition-group name="slide-in" :style="{ '--total': lists['loss_bid'].length }">
						<v-row
							v-for="(data, index) in lists['loss_bid']"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }} - {{ data.contractor.fullname }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>
								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0 text-tag-grey" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn v-if="data.bid.status === 'LB'" class="my-p-btn my_elevation" id="my_elevation" depressed disabled>Lost-Quote</v-btn>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>

				<div v-if="lists['accepted'] && lists['accepted'].length > 0">
					<p class="delivery-sf20 pb-5 pt-8 ma-0">
						Accepted Quotes
						<span class="delivery-mf15">• {{ lists['accepted'].length }} quotes</span>
					</p>

					<transition-group name="slide-in" :style="{ '--total': lists['accepted'].length }">
						<v-row
							v-for="(data, index) in lists['accepted']"
							v-bind:key="'list' + index"
							no-gutters
							:style="{ '--i': index }"
							class="list-box mb-3 d-sm-flex add-remove-item"
						>
							<div class="list-info d-flex">
								<div v-if="!data.bid.status" class="no-bid-point"></div>
								<div class="list-icon d-flex align-center justify-center">
									<img v-if="data.list.category_id === 1" :src="$store.state.icons.icons['Lumber']" alt />
									<img v-if="data.list.category_id === 2" :src="$store.state.icons.icons['Electric']" alt />
									<img v-if="data.list.category_id === 3" :src="$store.state.icons.icons['Plumbing']" alt />
									<img v-if="data.list.category_id === 4" :src="$store.state.icons.icons['Masonry']" alt />
									<img v-if="data.list.category_id === 5" :src="$store.state.icons.icons['ToolBoxes']" alt />
									<img v-if="data.list.category_id === 6" :src="$store.state.icons.icons['Landscape']" alt />
								</div>
								<div class="list-detail d-flex align-center pl-8 pr-8">
									<div style="width: 100%">
										<p class="heading-mf15">{{ data.list.list_name }} - {{ data.contractor.fullname }}</p>
										<div class="d-flex">
											<p class="heading-rf13">List ref: {{ data.list.list_id }}</p>

											<p v-if="data.list.active_bids > 0" class="heading-rf13 d-flex ml-auto">Quotes: {{ data.list.active_bids }}</p>
										</div>
									</div>
								</div>
							</div>

							<div class="list-address-info d-flex align-center pl-8 pr-8">
								<div class="pt-3 pb-3">
									<p class="heading-mf15">{{ firstAddress(data.list.address) }}</p>
									<p class="heading-rf13">{{ lastAddress(data.list.address) }}</p>
								</div>
							</div>

							<div class="list-bid-info d-flex align-center justify-space-between pl-8 pr-8">
								<div>
									<div v-if="!data.bid.status && data.list.status === 'A'">
										<app-list-hours v-model="data.list.idle_time"></app-list-hours>
									</div>
									<div v-else>
										<p class="heading-mf15">Your Quote</p>
										<p class="heading-rf13-blue">{{ $options.filters.currency(data.bid.total_amount) }}</p>
									</div>
								</div>

								<div v-if="getUserChatsRoomIds.includes(data.listDistCompanyId)" style="position: relative" @click="displayChatScreen(data)">
									<i class="mdi mdi-message-outline chat-icon"></i>
									<chatMessageCount :chatOpen="show_user_chat" :id="data.listDistCompanyId" />
								</div>
							</div>

							<div class="status-info d-sm-flex align-center pl-8 pr-8">
								<div class="ml-auto">
									<div v-if="data.bid.status === 'A'" class="text-tag-btn text-tag-green d-flex align-center justify-center mr-2">
										Accepted
									</div>
									<div v-else-if="data.bid.status === 'LB'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Lost-Quote
									</div>
									<div v-else-if="data.list.status === 'E'" class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2">
										List Expired
									</div>
									<div
										v-else-if="data.bid.status != 'A' && data.bid.status != 'R'"
										class="text-tag-btn text-tag-blue d-flex align-center justify-center mr-2"
									>
										<p class="ma-0" v-if="!data.bid.status">Awaiting your quote</p>
										<p class="ma-0 text-tag-grey" v-else-if="data.bid.status === 'E'">Quote Expired</p>
										<p class="ma-0" v-else-if="data.bid.tag_status">{{ data.bid.tag_status }}</p>
										<p class="ma-0" v-else-if="data.bid.status === 'AC'">Submited</p>
									</div>
									<div v-else-if="data.bid.status === 'R'" class="text-tag-btn text-tag-red d-flex align-center justify-center mr-2">
										Rejected
									</div>
								</div>
								<div class="my-btn-disabled">
									<v-btn
										v-if="data.bid.status === 'A'"
										class="my-p-btn my_elevation delivery-btn"
										id="my_elevation"
										depressed
										@click.prevent="bidDelivery(data.bid.bid_id)"
										>Delivery</v-btn
									>
								</div>
							</div>
						</v-row>
					</transition-group>
				</div>
			</div>
			<v-col cols="12" class="header-link d-sm-none d-flex justify-end align-center pb-7">
				<span v-on:click="filterList()" class="d-flex align-center" style="font-size: 18px">
					Filter
					<img :src="$store.state.icons.icons['filter-icon']" style="height: 24px" class="pl-1" alt />
				</span>
			</v-col>
		</v-col>

		<app-filter ref="filter" @refreshFilter="refreshFilter"></app-filter>
		<app-bid ref="bid"></app-bid>
		<app-re-bid ref="rebid"></app-re-bid>
		<app-check-bid ref="checkbid"></app-check-bid>
		<!-- <app-change-bid ref="checkbid"></app-change-bid> -->
		<app-bid-delivery ref="deliverybid"></app-bid-delivery>
		<!-- <chat-haggle-icon ref="chatHaggle"></chat-haggle-icon> -->
		<chat-message
			:is_show="show_user_chat"
			:isUserBlocked="isUserBlocked"
			@openUserChat="openUserChat()"
			@displayChangeBid="displayChangeBid"
			ref="chatMessage"
		/>
		<change-bid ref="changebid" />
	</v-row>
</template>

<script>
const ListView = () => import('../../mycomponents/List')
const Filter = () => import('../../mycomponents/Filter')
const Bid = () => import('../bidcomponent/Bid')
const ChangeBid = () => import('../bidcomponent/ChangeBid')
const ReBid = () => import('../bidcomponent/ReBid')
const CheckBid = () => import('../bidcomponent/CheckBid')
const BidDelivery = () => import('../bidcomponent/BidDelivery')
const ChatHaggleIcon = () => import('../../mycomponents/ChatHaggleIcon.vue')
const ChatMessage = () => import('../../mycomponents/ChatMessage.vue')
const changeBid = () => import('../bidcomponent/ChangeBid.vue')
const chatMessageCount = () => import('../../mycomponents/chatMessageCount.vue')

import { lists, recentLists } from '../../services/listservice'
import { errorLogs } from '../../services/authservices'
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../../config/variable'

export default {
	components: {
		AppFilter: Filter,
		AppListView: ListView,
		AppBid: Bid,
		AppReBid: ReBid,
		AppCheckBid: CheckBid,
		AppBidDelivery: BidDelivery,
		ChatHaggleIcon: ChatHaggleIcon,
		AppChangeBid: ChangeBid,
		ChatMessage: ChatMessage,
		changeBid: changeBid,
		chatMessageCount: chatMessageCount,
	},

	data() {
		return {
			listDistCompanyId: [],
			isUserBlocked: false,
			show_user_chat: false,
			filter: 'all',
			filter_by: {
				new_list: true,
				active: true,
				expired: true,
				rejected: true,
				accepted: true,
				loss_bid: true,
			},
			page: 1,
			my_timer: 0,
			itemsPerPage: 25,
			new_list: true,
			no_data: false,
			list_data: [],
			alert: {
				show: true,
				message: 'success',
				type: 'success',
			},
		}
	},

	created() {
		this.$store.watch(
			(state) => {
				return state.list
			},
			() => {
				this.updateList()
			},
			{ deep: true }
		)

		if (this.$store.state.auth.not_supported) {
			this.autoUpdate()
		}
	},

	beforeDestroy() {
		clearInterval(this.my_timer)
	},

	async mounted() {
		if (this.$store.state.list.is_new_list_filter) {
			this.filter_by.new_list = true
			this.filter_by.active = false
			this.filter_by.expired = false
			this.filter_by.rejected = false
			this.filter_by.accepted = false
			this.filter_by.loss_bid = false
		}

		this.$store.state.list.is_new_list_filter = false

		let data = await lists()
		let dataset = data.dataset
		this.list_data = []
		if (dataset && dataset.length > 0) {
			dataset.forEach(async (item) => {
				let listitems = []
				let biditems = []
				let reason_ids = []
				let list = {}
				let bid = {}
				let contractor = {}
				let listDistCompanyId = ''
				let chat_data = {}

				item.listitems.forEach((item) => {
					let ob = {
						name: item.name,
						qty: item.qty,
						price: 0.0,
						notes: null,
						show_note: false,
						url: item.url,
					}
					listitems.push(ob)
				})

				listitems.forEach((item) => {
					let data = item.notes != null
					item['show_note'] = data
				})

				list = {
					list_id: item.list_id,
					contractor_id: item.contractor_id,
					category_id: item.category_id,
					list_name: item.list_name,
					delivery_date: item.delivery_date,
					pickup: item.pickup,
					address: item.address,
					delivery_time: item.delivery_time,
					notes: item.notes,
					status: item.status,
					idle_time: item.idle_time,
					latitude: item.latitude,
					longitude: item.longitude,
					active_bids: item.active_bids,
					listitems: listitems,
					url: item.url,
				}

				if (item.last_bid) {
					if (item.last_bid.reason_ids) {
						item.last_bid.reason_ids.forEach((item) => {
							reason_ids.push(item)
						})
					} else {
						reason_ids.push(0)
					}

					item.last_bid.biditems.forEach((item) => {
						let ob = {
							name: item.name,
							qty: item.qty,
							price: item.price,
							notes: item.notes,
							modification: item.modification,
							show_note: false,
							url: item.url,
						}

						biditems.push(ob)
					})

					biditems.forEach((item) => {
						let data = item.notes != null
						item['show_note'] = data
					})

					bid = {
						bid_id: item.last_bid.bid_id,
						list_id: item.last_bid.list_id,
						date_of_delivery: item.last_bid.date_of_delivery,
						sub_total: item.last_bid.sub_total,
						shipping: item.last_bid.shipping,
						service_fee: item.last_bid.service_fee,
						sales_tax: item.last_bid.sales_tax,
						tax_percentage: item.last_bid.tax_percentage,
						total_amount: item.last_bid.total_amount,
						modification: item.last_bid.modification,
						reason_ids: reason_ids,
						comments: item.last_bid.comments,
						tag_status: item.last_bid.tag_status,
						status: item.last_bid.status,
						created_at: item.last_bid.created_at,
						time_of_delivery: item.last_bid.time_of_delivery,
						latitude: item.last_bid.latitude,
						longitude: item.last_bid.longitude,
						start_time: item.last_bid.start_time,
						end_time: item.last_bid.end_time,
						biditems: biditems,
					}

					listDistCompanyId = `${this.$store.state.auth.user_data.company_id}DC` + `${item.list_id}L`

					let userCollection = await firebase
						.firestore()
						.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
						.doc(listDistCompanyId)
						.get()
					chat_data = userCollection.data()
				}

				if (item.contractor) {
					contractor = {
						fullname: item.contractor.fullname,
						phone: item.contractor.phone,
					}
				}

				let ob = {
					list: list,
					bid: bid,
					contractor: contractor,
					listDistCompanyId: listDistCompanyId,
					chat_data: chat_data,
				}

				this.list_data.push(ob)
			})
		} else {
			this.no_data = true
		}

		let userCollection = await firebase
			.firestore()
			.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
			.where('is_active_chat', '==', true)
		userCollection.onSnapshot((doc) => {
			this.listDistCompanyId = []
			doc.forEach((doc) => {
				this.listDistCompanyId.push(doc.id)
			})
		})

		if (this.$route.query.data) {
			let queryData = JSON.parse(this.$route.query.data)
			let bidInterval = setInterval(() => {
				if (this.list_data.length > 0) {
					if (queryData.action === 'reject quote') {
						this.reBid(queryData.id)
					} else if (queryData.action === 'accept quote') {
						this.bidDelivery(queryData.id)
					} else if (queryData.action === 'create list') {
						this.bid(queryData.id)
					}
					clearInterval(bidInterval)
				}
			}, 500)
		}
	},

	watch: {
		async $route(to) {
			if (to.query.data) {
				let queryData = JSON.parse(to.query.data)
				setTimeout(() => {
					if (this.list_data.length > 0) {
						if (queryData.action === 'reject quote') {
							this.reBid(queryData.id)
						} else if (queryData.action === 'create list') {
							this.bid(queryData.id)
						}
					}
				})
			} else {
				this.$refs.rebid.closeBidBox()
				this.$refs.bid.closeBidBox()
			}
		},
		deep: true,
		immediate: true,
	},

	computed: {
		getUserChatsRoomIds() {
			return this.listDistCompanyId
		},

		lists() {
			if (this.list_data.length > 0) {
				var lists = []
				lists['new_list'] = ''
				lists['active'] = ''
				lists['rejected'] = ''
				lists['expired'] = ''
				lists['accepted'] = ''
				lists['loss_bid'] = ''

				// eslint-disable-next-line no-unused-vars
				var count = 0

				if (this.filter_by.new_list === true) {
					let new_list = this.list_data.filter((list) => {
						return (list.list.status === 'A' && Object.keys(list.bid).length === 0) || (list.list.status === 'A' && list.bid.bid_id === '')
					})

					lists['new_list'] = new_list
					count++
				}

				if (this.filter_by.active === true) {
					let active = this.list_data.filter((list) => {
						if (list.list.status === 'A') {
							return list.bid.status === 'AC'
						}
					})
					lists['active'] = active
					count++
				}

				if (this.filter_by.expired === true) {
					let expired = this.list_data.filter((list) => {
						if (list.list.status === 'A') {
							return list.bid.status === 'E'
						}
					})
					lists['expired'] = expired
					count++
				}

				if (this.filter_by.rejected === true) {
					let rejected = this.list_data.filter((list) => {
						return list.bid.status === 'R'
					})
					lists['rejected'] = rejected
					count++
				}

				if (this.filter_by.accepted === true) {
					let accepted = this.list_data.filter((list) => {
						return list.bid.status === 'A'
					})
					lists['accepted'] = accepted
					count++
				}

				if (this.filter_by.loss_bid === true) {
					let loss_bid = this.list_data.filter((list) => {
						return list.bid.status === 'LB'
					})
					lists['loss_bid'] = loss_bid
					count++
				}

				if (
					lists['new_list'].length === 0 &&
					lists['active'].length === 0 &&
					lists['rejected'].length === 0 &&
					lists['expired'].length === 0 &&
					lists['accepted'].length === 0 &&
					lists['loss_bid'].length === 0
				) {
					this.no_data = true
				} else {
					this.no_data = false
				}
				return lists
			}
		},
	},

	methods: {
		async filterList() {
			let filter_by = await this.$refs.filter.open('Filter', this.filter_by)
			this.refreshFilter(filter_by)
		},

		refreshFilter(filter_by) {
			this.filter_by = Object.assign(this.filter_by, filter_by)
		},

		firstAddress(address) {
			if (address) {
				let first_address = address.split(',')
				return first_address[0]
			}
		},

		lastAddress(address) {
			if (address) {
				let last_address = address.split(',')
				let full_address = last_address[1] != undefined ? last_address[1] : ''
				full_address = last_address[2] != undefined ? full_address + ', ' + last_address[2] : full_address
				full_address = last_address[3] != undefined ? full_address + ', ' + last_address[3] : full_address
				return full_address
			}
		},

		updateList() {
			errorLogs('update list function call in list page')
			errorLogs('list_id : ' + this.$store.state.list.list_data.list.list_id)
			if (this.$store.state.list.list_data.list.list_id != '') {
				this.$store.commit('listBidAccepted', { list_id: '', status: '' })
				let data = JSON.parse(JSON.stringify(this.$store.state.list.list_data))
				if (this.list_data.length > 0) {
					this.list_data.forEach((item, index) => {
						if (item.list.list_id === data.list.list_id) {
							if (data.bid.bid_id != '' && item.bid.bid_id === data.bid.bid_id) {
								data.list.active_bids = this.list_data[index].list.active_bids
								this.list_data[index] = JSON.parse(JSON.stringify(data))
								this.new_list = false

								this.refreshData()
							} else {
								data.list.active_bids = this.list_data[index].list.active_bids
								this.list_data[index] = JSON.parse(JSON.stringify(data))
								this.new_list = false

								this.refreshData()
							}
						}
					})
				}

				if (this.new_list === true) {
					errorLogs('new list added')
					this.list_data.splice(0, 0, JSON.parse(JSON.stringify(data)))

					this.refreshData()
				}
			}

			if (this.list_data.length > 0) {
				if (this.$store.state.list.cancel_list.list_id != '') {
					errorLogs(this.$store.state.list.cancel_list.list_id)
					this.list_data.forEach((item, index) => {
						if (item.list.list_id == this.$store.state.list.cancel_list.list_id) {
							this.list_data.splice(index, 1)
							errorLogs('list remove')
						}
					})

					this.refreshData()
				}

				if (this.$store.state.list.list_bid_accepted.list_id != '') {
					this.list_data.forEach((item, index) => {
						if (item.list.list_id == this.$store.state.list.list_bid_accepted.list_id) {
							if (this.$store.state.list.list_bid_accepted.status != '') {
								errorLogs('list status change')
								item.list.status = this.$store.state.list.list_bid_accepted.status
								if (this.$store.state.list.list_bid_accepted.status != 'A') {
									if (!item.bid.status) {
										this.list_data.splice(index, 1)
									}
								}
								//this.$store.commit('listBidAccepted', {list_id: '', status: ''});
							}
						}
					})

					this.refreshData()
				}

				if (this.$store.state.list.reset_list_counter.list_id != '') {
					this.list_data.forEach((item) => {
						if (item.list.list_id == this.$store.state.list.reset_list_counter.list_id) {
							if (this.$store.state.list.reset_list_counter.idle_time != '') {
								errorLogs('list idle time change')
								item.list.idle_time = this.$store.state.list.reset_list_counter.idle_time
								//this.$store.commit('resetListCounter', {list_id: '', idle_time: ''});
							}
						}
					})

					this.refreshData()
				}

				if (this.$store.state.list.change_active_bids.list_id != '') {
					this.list_data.forEach((item) => {
						if (item.list.list_id == this.$store.state.list.change_active_bids.list_id) {
							if (this.$store.state.list.change_active_bids.active_bids != '') {
								errorLogs('list active bid change')
								item.list.active_bids = this.$store.state.list.change_active_bids.active_bids
								//this.$store.commit('changeActiveBids', {list_id: '', active_bids: ''});
							}
						}
					})

					this.refreshData()
				}

				if (this.$store.state.list.bid_tag_status.list_id != '') {
					this.list_data.forEach((item) => {
						if (item.list.list_id == this.$store.state.list.bid_tag_status.list_id) {
							if (this.$store.state.list.bid_tag_status.tag_status != '') {
								errorLogs('bid tag status change')
								item.bid.tag_status = this.$store.state.list.bid_tag_status.tag_status
							}
						}
					})

					this.refreshData()
				}
			}
		},

		getList(type, id) {
			let data = {}
			if (type === 'list') {
				data = this.list_data.filter((list) => {
					return list.list.list_id === id
				})
			} else if (type === 'bid') {
				data = this.list_data.filter((bid) => {
					return bid.bid.bid_id === id
				})
			}

			return {
				list: data[0]['list'],
				bid: data[0]['bid'],
				contractor: data[0]['contractor'],
				notification: {},
			}
		},

		bid(list_id) {
			let data = this.getList('list', list_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.bid.bidSubmit(list_id)
		},

		reBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.rebid.reBidSubmit(bid_id)
		},

		checkBid(bid_id) {
			let data = this.getList('bid', bid_id)
			this.$store.commit('listReset')
			this.$store.commit('listData', JSON.parse(JSON.stringify(data)))
			this.$refs.checkbid.checkBidSubmit(bid_id)
		},

		bidDelivery(bid_id) {
			this.$refs.deliverybid.deliverySubmit(bid_id)
		},

		refreshData() {
			if (this.filter_by.new_list === true) {
				this.filter_by.new_list = false
				this.filter_by.new_list = true
			}
			if (this.filter_by.active === true) {
				this.filter_by.active = false
				this.filter_by.active = true
			}
			if (this.filter_by.expired === true) {
				this.filter_by.expired = false
				this.filter_by.expired = true
			}
			if (this.filter_by.rejected === true) {
				this.filter_by.rejected = false
				this.filter_by.rejected = true
			}
			if (this.filter_by.accepted === true) {
				this.filter_by.accepted = false
				this.filter_by.accepted = true
			}
			if (this.filter_by.loss_bid === true) {
				this.filter_by.loss_bid = false
				this.filter_by.loss_bid = true
			}
		},

		async autoUpdate() {
			this.my_timer = setInterval(() => {
				// Recent List
				this.recentList()
			}, 30000)
		},

		async recentList() {
			let data = await recentLists()
			let dataset = data.dataset

			if (dataset && dataset.length > 0) {
				dataset.forEach((item) => {
					let listitems = []
					let biditems = []
					let reason_ids = []
					let list = {}
					let bid = {}
					let contractor = {}

					item.listitems.forEach((item) => {
						let ob = {
							name: item.name,
							qty: item.qty,
							price: 0.0,
							notes: null,
							show_note: false,
							url: item.url,
						}
						listitems.push(ob)
					})

					listitems.forEach((item) => {
						let data = item.notes != null
						item['show_note'] = data
					})

					list = {
						list_id: item.list_id,
						contractor_id: item.contractor_id,
						category_id: item.category_id,
						list_name: item.list_name,
						delivery_date: item.delivery_date,
						pickup: item.pickup,
						address: item.address,
						delivery_time: item.delivery_time,
						notes: item.notes,
						status: item.status,
						idle_time: item.idle_time,
						latitude: item.latitude,
						longitude: item.longitude,
						active_bids: item.active_bids,
						listitems: listitems,
						url: item.url,
					}

					if (item.last_bid) {
						if (item.last_bid.reason_ids) {
							item.last_bid.reason_ids.forEach((item) => {
								reason_ids.push(item)
							})
						} else {
							reason_ids.push(0)
						}

						item.last_bid.biditems.forEach((item) => {
							let ob = {
								name: item.name,
								qty: item.qty,
								price: item.price,
								notes: item.notes,
								modification: item.modification,
								show_note: false,
								url: item.url,
							}

							biditems.push(ob)
						})

						biditems.forEach((item) => {
							let data = item.notes != null
							item['show_note'] = data
						})

						bid = {
							bid_id: item.last_bid.bid_id,
							list_id: item.last_bid.list_id,
							date_of_delivery: item.last_bid.date_of_delivery,
							sub_total: item.last_bid.sub_total,
							shipping: item.last_bid.shipping,
							service_fee: item.last_bid.service_fee,
							sales_tax: item.last_bid.sales_tax,
							tax_percentage: item.last_bid.tax_percentage,
							total_amount: item.last_bid.total_amount,
							modification: item.last_bid.modification,
							reason_ids: reason_ids,
							comments: item.last_bid.comments,
							tag_status: item.last_bid.tag_status,
							status: item.last_bid.status,
							created_at: item.last_bid.created_at,
							time_of_delivery: item.last_bid.time_of_delivery,
							latitude: item.last_bid.latitude,
							longitude: item.last_bid.longitude,
							start_time: item.last_bid.start_time,
							end_time: item.last_bid.end_time,
							biditems: biditems,
						}
					}

					if (item.contractor) {
						contractor = {
							fullname: item.contractor.fullname,
							phone: item.contractor.phone,
						}
					}

					let ob = {
						list: list,
						bid: bid,
						contractor: contractor,
					}

					let list_index = this.list_data.findIndex((noti) => noti.list.list_id == ob.list.list_id)

					if (list_index >= 0) {
						this.list_data[list_index] = ob
					} else {
						this.list_data.splice(0, 0, ob)
					}

					this.refreshData()
				})
			}
		},

		async displayChatScreen(data) {
			this.$refs.chatMessage.resetUserMessages()
			this.show_user_chat = true

			this.$store.state.chathaggle.collection_id = data.listDistCompanyId
			this.$store.state.chathaggle.active_bid_id = data.bid.bid_id

			this.$store.state.chathaggle.contractor_id = data.list.contractor_id

			this.$refs.chatMessage.getUserMessages(data.listDistCompanyId)

			this.$refs.changebid.getLastBidObjId(data.listDistCompanyId)

			let user = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'users')
				.doc(`${data.list.contractor_id}C`)
			user.onSnapshot((doc) => {
				this.$store.dispatch('setChatUser', doc.data())
			})
			this.setZeroCount()
			this.userBlocked()
		},

		async setZeroCount() {
			let msgCountSnapshot = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
				.doc(this.$store.state.chathaggle.collection_id)
				.get()
			let count = msgCountSnapshot.data()
			if (count.new_message_count) {
				await firebase
					.firestore()
					.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
					.doc(this.$store.state.chathaggle.collection_id)
					.update({ new_message_count: 0 })
			}
		},

		async userBlocked() {
			if (this.$store.state.chathaggle.collection_id) {
				let snapshot = await firebase
					.firestore()
					.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
					.doc(this.$store.state.chathaggle.collection_id)
				snapshot.onSnapshot(async (doc) => {
					this.isUserBlocked = await doc.data().isUserBlocked
				})
			}
		},

		async openUserChat() {
			this.setZeroCount()
			setTimeout(() => {
				this.show_user_chat = false
			}, 100)

			setTimeout(() => {
				this.isUserBlocked = false
			}, 300)
		},

		displayChangeBid(data) {
			this.$refs.changebid.checkBidSubmit(data)
		},
	},
}
</script>

<style lang="scss">
.chat-icon {
	padding: 10px 10px 7px;
	color: #0074ff;
	font-size: 25px;
	border: 1px solid #e9ecf4;
	border-radius: 4px;
	width: 40px;
	height: 40px;
	cursor: pointer;
}

.check-quote-btn {
	background: #0074ff !important;
}
.re-quote-btn {
	background: #fa9526 !important;
}
.delivery-btn {
	background: #8d41b1 !important;
}

.text-tag-grey {
	color: #7587a3 !important;
}
</style>
